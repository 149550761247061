import { Feature, FeatureCollection } from "geojson";
import _ from "lodash";

import { updateItemInArray } from "containers/workflows/subcategories/jobs/Jobscreen/notification/EmailNotificationConfigForm/utils/updateItemInArray";
import { IAction } from "redux/store/model";

import {
  IChangeSubcategorySuccessActionCreator,
  ISetCustomerAttributeActionCreator,
  ISetCustomerLocationsActionCreator,
  ISetCustomersLegendActionCreator,
} from "./actionCreator";
import * as types from "./actionTypes";
import initialState, { ICustomersState } from "./initialState";
import { prepareLegendForFE } from "./utils";
export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.SET_CUSTOMERS_ATTRIBUTE: {
      const { attributeTag, attributeData, isGeoAttribute } =
        action as ISetCustomerAttributeActionCreator;

      const customerLocations = (state as ICustomersState).customerLocations;

      _.forEach(attributeData, (ad) => {
        const customer = customerLocations?.find((c) => c._id === ad._id);
        const value = ad[attributeTag];

        // let processedValue = value ? (isGeoAttribute ? JSON.parse(value) : value) : null

        let processedValue: Feature | FeatureCollection | null = value || null;

        if (isGeoAttribute && processedValue) {
          processedValue = JSON.parse(value);

          if (processedValue?.type === "Feature") {
            _.set(processedValue as Feature, "properties.name", ad.name);
            const newFeatureCollection: FeatureCollection = {
              type: "FeatureCollection",
              features: [processedValue as Feature],
            };
            processedValue = newFeatureCollection;
          }
          if (processedValue?.type === "FeatureCollection") {
            _.forEach(
              processedValue.features,
              (feature: Feature, index: number) => {
                _.set(feature, "properties.name", ad.name);
                _.set(feature, "properties.index", index);
              }
            );
          }
        }

        if (customer) {
          _.set(customer, attributeTag, processedValue);
        }
      });

      return {
        ...state,
        customerLocations,
      };
    }

    case types.CHANGE_SUBCATEGORY_SELECTED_SUCCESS: {
      const { subcategory } = action as IChangeSubcategorySuccessActionCreator;
      return {
        ...state,
        subCategorySelected: subcategory,
      };
    }
    case types.SET_CUSTOMERS_LEGEND: {
      const { legend, lang } = action as ISetCustomersLegendActionCreator;
      legend.lastUpdated = new Date();
      prepareLegendForFE(legend, lang);
      const allLegends = updateItemInArray(state.allLegends, legend, {
        tag: legend.tag,
      });
      return {
        ...state,
        allLegends,
      };
    }
    case types.SET_CUSTOMER_LOCATIONS: {
      const { customerLocations } =
        action as ISetCustomerLocationsActionCreator;
      return {
        ...state,
        customerLocations,
      };
    }
    case types.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
